"use client"

import React, { useEffect } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import moment from "moment"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { API } from "@/api"

import { cn } from "@/lib/utils"
import { MarkdownEditor } from "@/components/markdown/editor"
import { Button } from "@/ui/button"
import { Checkbox } from "@/ui/checkbox"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/ui/form"
import { Input } from "@/ui/input"
import { useToast } from "@/ui/use-toast"

import {
  ICollectionField,
  ICollectionFieldType,
} from "@/types/collections/types"
import { IForm } from "@/types/forms/types"

import { Heading } from "../heading"

interface IProps {
  formId: string
  onSubmit?: () => void
}

export default function FormComponent(props: IProps) {
  const [formData, setFormData] = React.useState<IForm>()
  const [fields, setFields] = React.useState<ICollectionField[]>([])
  const [submitting, setSubmitting] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const { toast } = useToast()
  useEffect(() => {
    API.forms.getByFormId(props.formId).then((res) => {
      setFormData(res)
    })
    API.forms.getFields(props.formId).then((res) => {
      setFields(res)
    })
  }, [props.formId])

  let formConfig = {}
  let defaultValues = {}
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]
    let conf
    let defaultValue
    if (field.type === ICollectionFieldType.TEXT) {
      conf = z.string()
      defaultValue = field.defaultValue.toString()
    } else if (field.type === ICollectionFieldType.EMAIL) {
      conf = z.string().email()
      if (!field.required) {
        conf.optional()
      }
      defaultValue = field.defaultValue.toString()
    } else if (field.type === ICollectionFieldType.NUMBER) {
      conf = z.number()
      if (!field.required) {
        conf.optional()
      }
      defaultValue = field.defaultValue
    } else if (field.type === ICollectionFieldType.DATE) {
      conf = z.date()
      if (!field.required) {
        conf.optional()
      }
      defaultValue = field.defaultValue
    } else if (field.type === ICollectionFieldType.BOOLEAN) {
      conf = z.boolean()
      if (!field.required) {
        conf.optional()
      }
      defaultValue = field.defaultValue
    } else if (field.type === ICollectionFieldType.LINK) {
      conf = z.string().url()
      if (!field.required) {
        conf.optional()
      }
      defaultValue = field.defaultValue.toString()
    } else if (field.type === ICollectionFieldType.RICH_TEXT) {
      conf = z.string()
      if (!field.required) {
        conf.optional()
      }
      defaultValue = field.defaultValue.toString()
    } else if (field.type === ICollectionFieldType.COLOR) {
      conf = z.string()
      if (!field.required) {
        conf.optional()
      }
      defaultValue = field.defaultValue.toString()
    } else {
      continue
    }
    formConfig = {
      ...formConfig,
      [field.slug]: conf,
    }
    defaultValues = {
      ...defaultValues,
      [field.slug]: defaultValue,
    }
  }
  const formSchema = z.object(formConfig)
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...defaultValues,
    },
  })
  async function onSubmit(values: z.infer<typeof formSchema>) {
    setSubmitting(true)
    // TODO: add validation
    API.forms
      .submitForm(props.formId, values)
      .then((res) => {
        toast({
          title: "Success",
          description: "Form submitted successfully",
        })
        setSubmitted(true)
        props.onSubmit?.()
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: "Form submission failed",
        })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }
  if (submitted)
    return (
      <div>
        <div className="text-2xl font-bold">Thank you!</div>
        <div className="text-lg">
          Your form has been submitted successfully.
        </div>
        <Button
          onClick={() => {
            form?.reset()
            setSubmitted(false)
          }}
        >
          Submit another response
        </Button>
      </div>
    )
  return (
    <div className="flex flex-col gap-y-4">
      <div>
        <Heading variant={"h3"}>{formData?.title}</Heading>
        <div>{formData?.description}</div>
      </div>
      <Form {...form}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            console.log("submitting")
            console.log(form.getValues())
            form.handleSubmit(onSubmit)()
          }}
          className="space-y-2"
        >
          {fields.map((_field) => {
            const fieldType = _field.type
            return (
              <FormField
                key={_field.slug}
                control={form.control}
                name={_field.slug as never}
                disabled={submitting}
                render={({ field }) => (
                  <FormItem
                    className={cn({
                      "flex content-center items-center gap-x-1 flex-row":
                        _field.type == ICollectionFieldType.BOOLEAN,
                    })}
                  >
                    <FormLabel className={"capitalize"}>
                      {_field.title}
                    </FormLabel>
                    {fieldType === ICollectionFieldType.TEXT && (
                      <FormControl>
                        <Input
                          placeholder={_field.defaultValue.toString()}
                          {...field}
                        />
                      </FormControl>
                    )}
                    {fieldType === ICollectionFieldType.RICH_TEXT && (
                      <MarkdownEditor
                        {...field}
                        onChange={(e) => {
                          field.onChange(e)
                        }}
                        markdown={String(_field.defaultValue) as string}
                      />
                    )}
                    {fieldType === ICollectionFieldType.NUMBER && (
                      <FormControl>
                        <Input
                          type="number"
                          placeholder={_field.defaultValue.toString()}
                          {...field}
                          onChange={(e) => {
                            const value = Number(e.target.value)
                            if (isNaN(value)) return
                            field.onChange(value)
                          }}
                        />
                      </FormControl>
                    )}
                    {fieldType === ICollectionFieldType.BOOLEAN && (
                      <FormControl>
                        <Checkbox
                          {...field}
                          onCheckedChange={(checked) => {
                            field.onChange(checked)
                          }}
                        ></Checkbox>
                      </FormControl>
                    )}
                    {fieldType === ICollectionFieldType.DATE && (
                      <FormControl>
                        <Input
                          type="date"
                          {...field}
                          value={
                            field.value
                              ? moment(field.value).format("YYYY-MM-DD")
                              : ""
                          }
                          onChange={(e) => {
                            const value = e.target.value
                            // validate date
                            if (!value) return
                            const date = new Date(value)
                            if (isNaN(date.getTime())) return
                            field.onChange(date)
                          }}
                        />
                      </FormControl>
                    )}
                    {fieldType === ICollectionFieldType.LINK && (
                      <FormControl>
                        <Input
                          placeholder={_field.defaultValue.toString()}
                          {...field}
                        />
                      </FormControl>
                    )}
                    {fieldType === ICollectionFieldType.EMAIL && (
                      <FormControl>
                        <Input
                          placeholder={_field.defaultValue.toString()}
                          {...field}
                        />
                      </FormControl>
                    )}
                    {fieldType === ICollectionFieldType.COLOR && (
                      <FormControl>
                        <Input
                          type="color"
                          placeholder={_field.defaultValue.toString()}
                          {...field}
                        />
                      </FormControl>
                    )}
                    <FormMessage />
                  </FormItem>
                )}
              />
            )
          })}
          <div className="pt-4">
            <Button disabled={submitting} type="submit">
              Subscribe
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
