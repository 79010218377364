"use client"

import Link from "next/link"

import { cn } from "@/lib/utils"
import MaxWidthWrapper from "@/components/maxWidthWrapper"
import { CommandDialogMenu } from "@/components/search/dialog"
import SubscribeForm from "@/components/subscribeForm"

import { NavHeaderLinks } from "@/config/nav"
import { SiteConfig } from "@/config/site"

import MobileNav from "./nav-mobile"

export default function Nav() {
  return (
    <header>
      <MobileNav />
      <div
        className={cn(
          "sticky inset-x-0 top-0 z-30 w-full transition-all border-b border-border bg-background"
        )}
      >
        <MaxWidthWrapper>
          <nav className="flex h-14 items-center justify-between mx-auto max-w-6xl">
            <div className="flex items-center space-x-6">
              <Link href={"/"}>
                <div className="flex gap-x-1.5 items-center">
                  <span className="font-bold text-muted-foreground">
                    {SiteConfig.name}
                  </span>
                </div>
              </Link>
            </div>
            <div className="hidden sm:flex items-center space-x-6">
              {NavHeaderLinks.map((item) => {
                return (
                  <Link
                    href={item.href}
                    key={item.href}
                    target={item.newTab ? "_blank" : "_self"}
                    className="font-medium hover:text-foreground text-muted-foreground transition-colors"
                  >
                    {item.title}
                  </Link>
                )
              })}
              <CommandDialogMenu />
            </div>
            <div className="hidden sm:flex">
              <SubscribeForm />
            </div>
          </nav>
        </MaxWidthWrapper>
      </div>
    </header>
  )
}
