interface ILink {
  title: string
  href: string
  newTab?: boolean
}
export const NavHeaderLinks: ILink[] = [
  {
    title: "Medium",
    newTab: true,
    href: "https://medium.com/@kdpatel2803",
  },
  {
    title: "Blogs",
    newTab: false,
    href: "/blogs",
  },
  {
    title: "Email",
    newTab: true,
    href: "mailto:kdpatel2803@gmail.com",
  },
  {
    title: "Services",
    newTab: false,
    href: "/services",
  },
]
