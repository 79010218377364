import React from "react"
import { DialogTrigger } from "@radix-ui/react-dialog"
import { Mail } from "lucide-react"

import FormComponent from "@/components/forms"
import { Button } from "@/ui/button"
import { Dialog, DialogContent } from "@/ui/dialog"

export default function SubscribeForm() {
  const [open, setOpen] = React.useState(false)
  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open)
      }}
    >
      <DialogTrigger>
        <Button>
          <Mail />
          Subscribe
        </Button>
      </DialogTrigger>
      <DialogContent>
        <FormComponent formId="66c6f958fe2e231694fd264d/xK7QgiPsQOe41L7" />
      </DialogContent>
    </Dialog>
  )
}
