import React from "react"

import { cn } from "@/lib/utils"

interface IProps {
  children: React.ReactNode
  className?: string
}

export default function MaxWidthWrapper({ children, className }: IProps) {
  return (
    <div className={cn("max-w-7xl mx-auto px-4 sm:px-6 lg:px-8", className)}>
      {children}
    </div>
  )
}
